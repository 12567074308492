import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/analytics"

export const app = firebase.initializeApp({
  apiKey: "AIzaSyB-QIcwlyMPTgSWmE1JlqSLtO23WrsY-Ic",
  authDomain: "drippin-b39ee.firebaseapp.com",
  projectId: "drippin-b39ee",
  storageBucket: "drippin-b39ee.appspot.com",
  messagingSenderId: "401798313929",
  appId: "1:401798313929:web:fd425dd9224fb98fffda18",
});

export const analytics = app.analytics();

export const authFirebase = app.auth();

export const storageFirebase = app.storage();

export const refProducts = app.firestore().collection("products");

export const refAdmin = app.firestore().collection("admin");

export const refData = app.firestore().collection("data");

export const getUrlImg = (id = "", number = 0) =>
  `https://firebasestorage.googleapis.com/v0/b/drippin-b39ee.appspot.com/o/products%2F${id}%2F${number}.webp?alt=media`;
