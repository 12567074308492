import {
  LIMIT_GET_MORE_PRODUCTS,
  LIMIT_GET_PRODUCTS,
} from "components/constraints";
import DataContext from "components/Context/DataContext";
import FiltersContext from "components/Context/FiltersContext";
import ScrollContext from "components/Context/ScrollContext";
import NearScreen from "components/NearScreen";
import { isArrayEqual } from "components/utils";
import { logEvent } from "FirebaseControllers/analytics";
import { getProducts } from "FirebaseControllers/products";
import React, { useContext, useEffect, useState } from "react";

import "./css.css";
import ListProduct from "./ListProduct.js";

export default function ListProducts({ handleClickProduct = () => {} }) {
  const { products, setProducts } = useContext(DataContext);
  const { filterSizes, filterColors, filterOrderBy, filterCategorie } =
    useContext(FiltersContext);
  const { scrollStart } = useContext(ScrollContext);

  const [showGetMore, setShowGetMore] = useState(true);

  const [fetchingMoreProducts, setFetchingMoreProducts] = useState(false);

  const [filters, setFilters] = useState({
    filterCategorie,
    filterSizes,
    filterColors,
    filterOrderBy,
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const changeFilters =
      filters.filterCategorie !== filterCategorie ||
      !isArrayEqual(filters.filterSizes, filterSizes) ||
      filters.filterColors !== filterColors ||
      filters.filterOrderBy !== filterOrderBy;

    if (products === "loading" || changeFilters) {
      if (changeFilters) {
        setFilters({
          filterCategorie,
          filterSizes,
          filterColors,
          filterOrderBy,
        });
        scrollStart();
      }
      setProducts("searching");
      setLoading(true);
      if (products !== "searching") {
        getProducts({
          filterCategorie,
          filterSizes,
          filterColors,
          filterOrderBy,
        })
          .then((arr_products) => {
            setProducts(arr_products);
            if (arr_products.length >= LIMIT_GET_PRODUCTS) setShowGetMore(true);
            else setShowGetMore(false);
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
            setProducts("error");
          });
      }
    }
  }, [
    filterColors,
    filterSizes,
    filters,
    products,
    scrollStart,
    setProducts,
    filterOrderBy,
    filterCategorie,
  ]);

  /*   useEffect(() => {
    console.log(scrollProducts);
    const container = refAppContainer.current;
    console.log(container.scrollHeight);
    if (container) container.scroll(0, scrollProducts ? scrollProducts : 0);
    setShowStuffedScroll(false);
    return () => {
      console.log(container.scrollTop);
      setScrollProducts(container.scrollTop);
    };
  }, [scrollProducts, setScrollProducts, refAppContainer]); */

  useEffect(() => {
    scrollStart();
  }, [scrollStart]);

  const handleShowMoreProducts = () => {
    logEvent("show_more_products");
    if (!fetchingMoreProducts) {
      setFetchingMoreProducts(true);
      getProducts({
        filterCategorie,
        filterSizes,
        filterColors,
        filterOrderBy,
        lastProduct: products[products.length - 1],
      })
        .then((arr_products) => {
          setProducts((prevProducts) => [...prevProducts, ...arr_products]);
          if (arr_products.length >= LIMIT_GET_MORE_PRODUCTS)
            setShowGetMore(true);
          else setShowGetMore(false);
          setFetchingMoreProducts(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  if (products === "loading" || products === "searching" || loading) {
    let arr_products = [];
    for (let i = 1; i <= LIMIT_GET_PRODUCTS; i++) arr_products.push(i);
    return (
      <div className="container-list-products">
        {Array.isArray(arr_products) &&
          arr_products.map((product, i) => (
            <ListProduct
              loading
              key={product + i} //SACAR I
            />
          ))}
      </div>
    );
  }

  if (products === "error" || !products)
    return (
      <div className="container-error">
        <p className="error-title">¡UPS!</p>
        <p className="error-message">
          Ocurrió un error al cargar los productos, vuelve a intentarlo más
          tarde.
        </p>
      </div>
    );

  if (Array.isArray(products) && products.length <= 0)
    return (
      <div className="container-error">
        <p className="error-message">No se encontró ningún producto</p>
      </div>
    );

  return (
    <div style={{ width: "100%" }}>
      <div className="container-list-products">
        {Array.isArray(products) &&
          products.map((product, i) => (
            <ListProduct
              handleClickProduct={handleClickProduct}
              product={product}
              key={product.id + i} //SACAR I
            />
          ))}
      </div>
      {products !== "loading" && showGetMore && (
        <NearScreen handleNearScreen={handleShowMoreProducts} />
      )}
      {/*showStuffedScroll && <div className="stuffed-scroll"></div>*/}
    </div>
  );
}
