import DataContext from "components/Context/DataContext";
import ScrollContext from "components/Context/ScrollContext";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";

import "./css.css";
import { useToasts } from "react-toast-notifications";
import { getUrlImg } from "FirebaseControllers/config";
import {
  deleteProduct,
  getProduct,
  saveProduct,
  uploadImgProduct,
} from "FirebaseControllers/products";
import Loader from "components/Loader";
import NotFoundPage from "components/NotFoundPage";
import CurrencyFormat from "react-currency-format";
import Compress from "react-image-file-resizer";

const newProduct = {
  name: "",
  categorie: "",
  details: "",
  mainImage: 0,
  price: 0,
  quantityImages: 0,
  sizes: [],
};

export default function ProductAdmin({ isNew = false }) {
  const { sizes, categories, colors, products } = useContext(DataContext);

  const { scrollStart } = useContext(ScrollContext);

  const { addToast } = useToasts();

  const { id } = useParams();

  const history = useHistory();

  const [idProduct, setIdProduct] = useState(id);

  const [product, setProduct] = useState("loading");

  const [loadingSave, setLoadingSave] = useState(false);

  const [images, setImages] = useState([]);

  const [imageSelect, setImageSelect] = useState(0);

  const sizeAvailable = (size) => {
    if (Array.isArray(product.sizes)) {
      return product.sizes.includes(size);
    }
    return false;
  };

  const handleClickSize = (size) => {
    let sizesProduct = [...product.sizes];
    if (Array.isArray(product.sizes)) {
      if (sizesProduct.includes(size)) {
        sizesProduct = sizesProduct.filter((_size) => _size !== size);
      } else {
        sizesProduct.push(size);
      }
    }
    setProduct((prev) => ({ ...prev, sizes: sizesProduct }));
  };

  useEffect(() => {
    scrollStart();
  }, [scrollStart]);

  useEffect(() => {
    if (product === "loading" || idProduct !== id) {
      setIdProduct(id);
      if (isNew) {
        setProduct(newProduct);
        return;
      }
      if (Array.isArray(products)) {
        let _product = products.find((product) => product.id === id);
        if (_product) {
          setProduct(_product);
          let arr_images = [];
          if (_product.quantityImages) {
            for (let i = 0; i < _product.quantityImages; i++)
              arr_images.push({
                src: getUrlImg(_product.id, i),
                key: Math.floor(Math.random() * (10000 - 1)) + 1,
              });
            setImages(arr_images);
          }
          return;
        }
      }
      getProduct({ idProduct: id })
        .then((_product) => {
          setProduct(_product);
          if (_product.quantityImages) {
            let arr_images = [];
            for (let i = 0; i < _product.quantityImages; i++)
              arr_images.push({
                src: getUrlImg(_product.id, i),
                key: Math.floor(Math.random() * (10000 - 1)) + 1,
              });
            setImages(arr_images);
          }
        })
        .catch((e) => {
          console.log(e);
          setProduct("error");
        });
    }
  }, [id, idProduct, isNew, product, products]);

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setProduct((prev) => ({ ...prev, [name]: value }));
  };

  const handleSaveProduct = async () => {
    setLoadingSave(true);
    try {
      const res = await saveProduct({
        ...product,
        quantityImages: images.length,
        isNew,
      });
      let productId = isNew ? res.id : product.id;

      let promises = [];
      images.forEach(
        (image, index) =>
          image.image &&
          promises.push(uploadImgProduct(productId, index, image.image))
      );

      if (promises.length > 0) await Promise.all(promises);
      addToast("Guardado con éxito", {
        appearance: "success",
        autoDismiss: true,
      });
      setLoadingSave(false);
    } catch (error) {
      console.log(error);
      setLoadingSave(false);
      addToast("Ocurrio un error: " + error.message, {
        appearance: "error",
        autoDismiss: false,
      });
    }
  };

  const handleDeleteProduct = () => {
    if (window.confirm("¿Eliminar " + product.name + "?")) {
      deleteProduct(product.id)
        .then(() => {
          addToast("Eliminado con éxito", {
            appearance: "success",
            autoDismiss: true,
          });
          history.push("/");
        })
        .catch((error) => {
          console.log(error);
          addToast("Ocurrio un error: " + error.message, {
            appearance: "error",
            autoDismiss: false,
          });
        });
    }
  };

  const handleChangeImage = (e) => {
    let files = [...e.target.files];
    try {
      for (let image of files) {
        Compress.imageFileResizer(
          image, // the file from input
          1000, // width
          1250, // height
          "WEBP", // compress format WEBP, JPEG, PNG
          100, // quality
          0, // rotation
          (compressedFile) => {
            // You upload logic goes here
            setImages((prev) => [
              ...prev,
              {
                src: URL.createObjectURL(compressedFile),
                image: compressedFile,
                key: Math.floor(Math.random() * (10000 - 1)) + 1,
              },
            ]);
          },
          "blob" // blob or base64 default base64
        );
      }
    } catch (error) {
      addToast("Ocurrio un error: " + error.message, {
        appearance: "error",
        autoDismiss: false,
      });
    }
  };

  const handleRemoveImage = () => {
    let arr_images = [...images];
    arr_images.pop();
    if (imageSelect > 0) {
      if (imageSelect === images.length - 1) {
        if (product.mainImage === imageSelect)
          setProduct((prev) => ({ ...prev, mainImage: images.length - 2 }));

        setImageSelect(images.length - 2);
      }
    }
    setImages(arr_images);
  };

  const handleChangeMainImage = () =>
    setProduct((prev) => ({ ...prev, mainImage: imageSelect }));

  if (product === "loading") return <Loader />;

  if (product === "error")
    return (
      <div className="container-error">
        <p className="error-title">¡UPS!</p>
        <p className="error-message">
          Ocurrió un error al cargar el producto, vuelve a intentarlo más tarde.
        </p>
      </div>
    );

  if (!product) return <NotFoundPage />;

  return (
    <div className="container-product max-width appear-window">
      <div className="product-container-thumbnails-image">
        <div className="product-container-image">
          {images.length > 0 ? (
            images[imageSelect] && (
              <>
                <img
                  src={images[imageSelect].src}
                  alt="Imagen principal"
                  className="product-image"
                />
                {imageSelect === product.mainImage ? (
                  <div className="button product-admin-main-image main-image-select">
                    Imagen principal
                  </div>
                ) : (
                  <div
                    onClick={handleChangeMainImage}
                    className="button product-admin-main-image"
                  >
                    Hacer imagen principal
                  </div>
                )}
              </>
            )
          ) : (
            <label htmlFor="file-upload">
              <div className="button button-add-image-product">
                Agregar imagen
              </div>
            </label>
          )}
        </div>
        <div className="product-container-thumbnails">
          {Array.isArray(images) &&
            images.map((image, i) => (
              <div key={image.key} style={{ position: "relative" }}>
                <img
                  src={image.src}
                  alt="Imagen thumbnail"
                  onClick={() => setImageSelect(i)}
                  className={`product-image-thumbnails ${
                    imageSelect === i ? "product-info-rectangule-select" : ""
                  }`}
                />
                {i === images.length - 1 && (
                  <div
                    className="image-thumbnail-delete"
                    onClick={handleRemoveImage}
                  >
                    X
                  </div>
                )}
              </div>
            ))}
          <label htmlFor="file-upload">
            <div className="product-thumbnails-add button">+</div>
          </label>
          <input
            type="file"
            id="file-upload"
            style={{ display: "none" }}
            multiple
            onChange={handleChangeImage}
          />
        </div>
        <textarea
          type="text"
          className="product-name input-product  only-mobile"
          name="name"
          onChange={handleInputChange}
          value={product.name}
          placeholder="Nombre del producto"
        />
      </div>
      <div className="product-container-info">
        <div className="product-info-container-basic">
          <textarea
            type="text"
            className="product-name input-product"
            name="name"
            onChange={handleInputChange}
            value={product.name}
            placeholder="Nombre del producto"
          />
          <CurrencyFormat
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={"$"}
            className="product-price input-product"
            value={product.price}
            placeholder="Precio $"
            allowNegative={false}
            onValueChange={(values) => {
              const { floatValue } = values;
              // formattedValue = $2,223
              // value ie, 2223
              if (floatValue)
                setProduct((prev) => ({ ...prev, price: floatValue }));
              else setProduct((prev) => ({ ...prev, price: 0 }));
            }}
          />
        </div>

        {/* <div className="product-info-container-color">
          <div className="product-info-color">
            <div
              className={"product-info-color-content color-" + product.color}
            ></div>
          </div>
        </div> */}

        <hr className="product-hr" />

        <p className="product-info-subtitle">Talle</p>
        <div
          style={{ marginBottom: "30px" }}
          className="product-info-container-sizes"
        >
          {Array.isArray(sizes) &&
            sizes.map((size, i) => (
              <div
                key={size + i}
                className={`product-info-size ${
                  sizeAvailable(size) && "product-info-size-select"
                }`}
                onClick={() => handleClickSize(size)}
              >
                {size}
              </div>
            ))}
        </div>

        <hr className="product-hr" />

        <p className="product-info-subtitle">Categoria</p>
        <div
          style={{ marginBottom: "30px" }}
          className="product-info-container-sizes"
        >
          {Array.isArray(categories) &&
            categories.map((categorie, i) => (
              <div
                key={categorie + i}
                className={`product-info-rectangule ${
                  product.categorie === categorie &&
                  "product-info-rectangule-select"
                }`}
                onClick={() => setProduct((prev) => ({ ...prev, categorie }))}
              >
                {categorie}
              </div>
            ))}
        </div>
        <hr className="product-hr" />

        <p className="product-info-subtitle">Color</p>
        <div
          style={{ marginBottom: "30px" }}
          className="product-info-container-sizes"
        >
          {Array.isArray(colors) &&
            colors.map((color, i) => (
              <div
                key={color + i}
                className={`product-info-rectangule ${
                  product.color === color && "product-info-rectangule-select"
                }`}
                onClick={() => setProduct((prev) => ({ ...prev, color }))}
              >
                {color}
              </div>
            ))}
        </div>

        <div className="product-info-container-details">
          <p className="product-info-details-title">DETALLES</p>
          <p className="product-info-details-subtitle">{product.name}</p>

          <textarea
            type="text"
            className="product-info-details-detail input-product"
            name="details"
            onChange={handleInputChange}
            value={product.details}
            placeholder="Detalles del producto"
          />
        </div>

        {!isNew && (
          <div style={{ marginTop: "10px" }} className="flex-center">
            <button onClick={handleDeleteProduct} className="button">
              Eliminar Producto
            </button>
          </div>
        )}

        <div className="product-info-container-add-to-cart">
          <button
            onClick={handleSaveProduct}
            className="button button-gradient-blue"
            disabled={loadingSave}
          >
            {loadingSave ? <Loader button /> : "GUARDAR PRODUCTO"}
          </button>
        </div>
      </div>
    </div>
  );
}
