import { refData } from "./config";

export const getData = async () => {
  try {
    const docData = await refData.doc("data").get();

    if (!docData.exists) return null;

    return docData.data();
  } catch (error) {
    console.log(error);
    throw new Error(error.message);
  }
};

export const saveData = async (data) => {
  try {
    await refData.doc("data").set(data, { merge: true });
    return;
  } catch (error) {
    console.log(error);
    throw new Error(error.message);
  }
};

export const saveMeasures = async (measures) => {
  try {
    console.log(measures);
    await refData.doc("data").set({ measures: measures }, { merge: true });
    return;
  } catch (error) {
    console.log(error);
    throw new Error(error.message);
  }
};
