import React from "react";

export default function ListFilters({
  title = "",
  description = "",
  array_filters = [],
  onChange = () => {},
  addFilter = () => {},
  removeFilter = () => {},
}) {
  return (
    <div className="filters-container-list">
      <p className="filters-subtitle">{title}</p>
      <p className="filters-description">{description}</p>
      <li className="filters-list">
        {Array.isArray(array_filters) &&
          array_filters.map((filter, i) => (
            <ul className="filters-list-container-item" key={i}>
              <input
                className="input filters-list-item"
                value={filter}
                onChange={(e) => onChange(e.target.value, i)}
              />
              <div
                className="filter-list-item-x"
                onClick={() => removeFilter(i)}
              >
                X
              </div>
            </ul>
          ))}
      </li>
      <button className="button filter-list-button" onClick={addFilter}>
        Agregar
      </button>
    </div>
  );
}
