export const StyleHeightFullScreen = (res = 0) => {
  return window.innerWidth > 500
    ? `calc(90vh - ${res}px)`
    : window.innerHeight - res + "px";
};

export const isArrayEqual = (a, b) => {
  if (a.length !== b.length) return false;
  const uniqueValues = new Set([...a, ...b]);
  for (const v of uniqueValues) {
    const aCount = a.filter((e) => e === v).length;
    const bCount = b.filter((e) => e === v).length;
    if (aCount !== bCount) return false;
  }
  return true;
};