import { signOut } from "FirebaseControllers/admin";
import React from "react";
import { Link } from "react-router-dom";

import "./css.css";

export default function PanelAdmin() {
  return (
    <div className="container-panel-admin">
      <p className="panel-admin-title">PANEL DE ADMINISTRADOR</p>
      <Link to="/product/new" className="button">
        AGREGAR NUEVO PRODUCTO
      </Link>
      <Link to="/admin/filters" className="button">
        EDITAR FILTROS
      </Link>
      <Link to="/admin/measures" className="button">
        EDITAR MEDIDAS Y TALLES
      </Link>
      <button onClick={signOut} className="button">
        CERRAR SESIÓN
      </button>
    </div>
  );
}

/*
    array_buttons.push({ text: "AGREGAR NUEVO PRODUCTO", to: "/product/new" });
    array_buttons.push({ text: "EDITAR FILTROS", to: "/filters" });*/
