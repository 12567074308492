import { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error) {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }

  componentDidCatch(_error, _errorInfo) {
    // You can use your own error logging service here

    this.setState({ hasError: true });
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{
            height: this.props.isFullScreen ? "100vh" : "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: this.props.isFullScreen ? "100vh" : "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: this.props.isFullScreen ? "100vh" : "100%",
              }}
            >
              <h1
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  color: "#fff",
                  textAlign: "center",
                  marginBottom: "10px",
                }}
              >
                ¡Ups! Ocurrió un error
              </h1>
              <p
                style={{
                  fontSize: "0.9rem",
                  fontWeight: "bold",
                  color: "#fff",
                  textAlign: "center",
                  marginBottom: "20px",
                  width: "80%",
                }}
              >
                Por favor, recarga la página. En caso de que el error persista,
                realizar el pedido por whatsapp.
              </p>

              <button
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "#fff",
                  textAlign: "center",
                  padding: "10px 20px",
                  borderRadius: "10px",
                  border: "none",
                  backgroundColor: "rgb(var(--primary-rgb))",
                  cursor: "pointer",
                }}
                onClick={() => {
                  this.setState({ hasError: false });
                }}
              >
                Recargar
              </button>
            </div>
          </div>
        </div>
      );
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

const ErrorBoundaryWithRouter = (props) => {
  return <ErrorBoundary {...props} />;
};

export default ErrorBoundaryWithRouter;
