import Skeleton from "components/Skeleton";
import { getUrlImg } from "FirebaseControllers/config";
import React from "react";
import CurrencyFormat from "react-currency-format";
import { useImage } from "react-image";

export default function ListProduct({
  loading = false,
  product = {
    id: "",
    name: "",
    price: 0,
    mainImage: 0,
  },
  handleClickProduct = () => {},
}) {
  const { src } = useImage({
    srcList: loading ? "" : getUrlImg(product.id, product.mainImage),
    useSuspense: false,
  });

  if (loading)
    return (
      <div className={`container-list-product `}>
        <div className="list-product-container-image"></div>
        <Skeleton width={160} height={20} borderRadius={5} marginBottom={10} />
        <Skeleton width={60} height={20} borderRadius={5} />
      </div>
    );

  return (
    <div
      onClick={() => handleClickProduct(product.id)}
      className={`container-list-product `}
    >
      <div className="list-product-container-image">
        {src && (
          <img
            alt="Imagen del producto"
            src={src}
            className="list-product-image fade-in"
          />
        )}
      </div>
      <p className="list-product-name">{product.name}</p>
      <p className="list-product-price">
        <CurrencyFormat
          value={product.price}
          displayType={"text"}
          thousandSeparator={"."}
          decimalSeparator={","}
          prefix={"$"}
        />
      </p>
    </div>
  );
}
