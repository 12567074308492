import React, { useContext, useState } from "react";
import Accordion from "../Accordion";

import ListSelect from "./ListSelect";
import Modal from "./Modal";
import { ReactComponent as FilterSvg } from "img/filter.svg";

import "./css.css";
import DataContext from "components/Context/DataContext";
import FiltersContext from "components/Context/FiltersContext";

export default function FiltersProducts({ categorie = "" }) {
  const {
    filterSizes,
    selectFilterSize,
    filterColors,
    selectFilterColors,
    filterOrderBy,
    selectFilterOrderBy,
  } = useContext(FiltersContext);

  const { sizes, colors, orderBy } = useContext(DataContext);

  const [openModal, setOpenModal] = useState(null);

  const handleCloseModalMobile = () => setOpenModal(false);
  const handleOpenModalMobile = () => setOpenModal(true);

  return (
    <div className="container-filters-products">
      <Modal
        openModal={openModal}
        handleCloseModalMobile={handleCloseModalMobile}
      />
      <div className="filter-products-title-filters">
        <p className="filter-products-title">
          {categorie ? categorie : "todas las prendas"}
        </p>
        <button
          onClick={handleOpenModalMobile}
          className="mobile button button-white flex-center"
        >
          <FilterSvg className="icon-filter" />
          Filtros
        </button>
      </div>
      <div className="pc" style={{ display: "contents" }}>
        <Accordion
          title="Ordenar por"
          valuesSelect={filterOrderBy}
          children={
            <ListSelect
              value={filterOrderBy}
              setValue={selectFilterOrderBy}
              items={orderBy}
            />
          }
        />
        <Accordion
          title="Talles"
          valuesSelect={filterSizes}
          children={
            <ListSelect
              value={filterSizes}
              setValue={selectFilterSize}
              items={sizes}
            />
          }
        />
        <Accordion
          title="Color"
          valuesSelect={filterColors}
          children={
            <ListSelect
              value={filterColors}
              setValue={selectFilterColors}
              items={colors}
            />
          }
        />
      </div>
    </div>
  );
}
