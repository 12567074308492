import BagContext from "components/Context/BagContext";
import Burger from "components/NavBar/Burger";
import React, { useContext } from "react";
import ReactDOM from "react-dom";
import { ReactComponent as BagSvg } from "img/bag.svg";

import "./css.css";
import ProductBag from "./ProductBag";
import CurrencyFormat from "react-currency-format";
import { logEvent } from "FirebaseControllers/analytics";

export default function Bag({ openModal = false, handleCloseBag = () => {} }) {
  const { itemsBag } = useContext(BagContext);

  const getTotal = () => {
    let total = 0;
    itemsBag.forEach((product) => (total += product.price));
    return total;
  };

  const getTextWhatsapp = () => {
    const intro = "Hola%2C%20te%20consulto%20por%3A%0A%0A";
    let items = "";
    itemsBag.forEach(
      (product) =>
        (items += `%2A${product.name}%2A%0AColor%3A%20${product.color}%0ATalle%3A%20${product.size}%0ACodigo%3A%20${product.id}%0A%0A`)
    );

    return intro + items;
  };

  return ReactDOM.createPortal(
    <div
      className={`container-modal ${
        openModal === true
          ? "container-modal-open"
          : openModal === false
          ? "container-modal-close"
          : ""
      }`}
    >
      <div
        className={`container-modal-bag ${
          openModal === true
            ? "modal-filter-products-open"
            : openModal === false
            ? "modal-filter-products-close"
            : ""
        }`}
      >
        <div className="modal-filter-products-section modal-filter-products-section-close toggle">
          <p className="modal-filer-products-title">Bolsa de compras</p>
          <div onClick={handleCloseBag}>
            <Burger dark />
          </div>
        </div>
        {itemsBag.length <= 0 ? (
          <div className="modal-bag-container-empty">
            <BagSvg className="modal-bag-svg" />
            <p className="modal-bag-text-empty">
              TU BOLSA DE COMPRAS ESTÁ VACÍA
            </p>
          </div>
        ) : (
          <div className="modal-bag-container-products">
            {itemsBag.map((item) => (
              <ProductBag
                handleCloseBag={handleCloseBag}
                product={item}
                key={item.id + item.date + item.random}
              />
            ))}
            <div className="modal-bag-bottom">
              <div className="bag-container-total">
                <p>Total:</p>{" "}
                <p>
                  <CurrencyFormat
                    value={getTotal()}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"$"}
                  />
                </p>
              </div>
              <a
                href={`https://wa.me/5491124003000?text=${getTextWhatsapp()}`}
                rel="noopener noreferrer"
                target="_blank"
                className="consult consult-showproduct"
                onClick={() => logEvent("share_cart")}
              >
                <div className="button button-gradient-blue">
                  SOLICITAR PEDIDO POR WHATSAPP
                </div>
              </a>
            </div>
          </div>
        )}
      </div>
    </div>,
    document.getElementById("modal")
  );
}
