import React, { useState } from "react";
import { ReactComponent as GoogleSvg } from "img/google.svg";
import { signUpGoogle } from "FirebaseControllers/admin";
import Loader from "components/Loader";

import "./css.css";
import { useToasts } from "react-toast-notifications";

export default function Login() {
  const [loading, setLoading] = useState(false);

  const { addToast } = useToasts();

  const handleSignIn = () => {
    setLoading(true);
    signUpGoogle().catch((e) => {
      setLoading(false);
      addToast(e.message, {
        appearance: "error",
        autoDismiss: true,
      });
      console.log(e);
    });
  };

  if (loading) return <Loader />;

  return (
    <div className="container-login">
      <button onClick={handleSignIn} className="button button-google">
        <GoogleSvg className="logo-google" />
        Ingresar con Google
      </button>
    </div>
  );
}
