import React from "react";
/* import { ReactComponent as InstagramSvg } from "img/instagram.svg";
import { ReactComponent as WhatsappSvg } from "img/whatsapp.svg"; */
import { ReactComponent as DropSvg } from "img/drop.svg";

import "./css.css";

function Footer() {
  return (
    <footer className="footer">
      <DropSvg className="footer-drop-icon footer-icon" />
      {/* <div className="footer-container-socials">
        <ul className="footer-socials-container-icons">
          <li className="item">
            <a
              href="https://www.instagram.com/__drppn/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <InstagramSvg className="footer-icon" />
            </a>
          </li>
          <li className="item">
            <a
              href="https://api.whatsapp.com/send?phone=5492245427854"
              rel="noopener noreferrer"
              target="_blank"
            >
              <WhatsappSvg className="footer-icon" />
            </a>
          </li>
        </ul>
      </div> */}

      <a
        href="https://www.instagram.com/__drppn/"
        rel="noopener noreferrer"
        target="_blank"
        className="footer-visit-instagram"
      >
        Visita nuestro Instagram
      </a>

      <div className="footer-copyright">
        &copy; {new Date().getFullYear()} DRPPN - Desarrollado por{" "}
        <a
          href="https://wa.me/5492245427854"
          target="_blank"
          rel="noopener noreferrer"
          style={{ marginLeft: "5px" }}
        >
          Juan A. Martí
        </a>
      </div>
    </footer>
  );
}

export default Footer;
