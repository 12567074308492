import {
  LIMIT_GET_MORE_PRODUCTS,
  LIMIT_GET_PRODUCTS,
  ORDER_BY_HIGHER_PRICE,
  ORDER_BY_LOWER_PRICE,
} from "components/constraints";
import { refProducts, storageFirebase } from "./config";

export const getProducts = async ({
  filterCategorie = "",
  filterSizes = [],
  filterColors = [],
  filterOrderBy = "",
  lastProduct = null,
}) => {
  try {
    let queryFindProducts = refProducts;

    if (filterCategorie && filterCategorie.length > 0)
      queryFindProducts = queryFindProducts.where(
        "categorie",
        "==",
        filterCategorie
      );

    if (filterColors && filterColors.length > 0)
      queryFindProducts = queryFindProducts.where("color", "==", filterColors);

    if (Array.isArray(filterSizes) && filterSizes.length > 0)
      queryFindProducts = queryFindProducts.where(
        "sizes",
        "array-contains-any",
        filterSizes
      );

    if (filterOrderBy && filterOrderBy.length > 0) {
      if (filterOrderBy === ORDER_BY_HIGHER_PRICE)
        queryFindProducts = queryFindProducts.orderBy("price", "desc");
      else if (filterOrderBy === ORDER_BY_LOWER_PRICE)
        queryFindProducts = queryFindProducts.orderBy("price", "asc");
    } else {
      queryFindProducts = queryFindProducts.orderBy("date", "desc");
    }
    if (lastProduct) {
      queryFindProducts = queryFindProducts
        .startAfter(lastProduct.ref)
        .limit(LIMIT_GET_MORE_PRODUCTS);
    } else {
      queryFindProducts = queryFindProducts.limit(LIMIT_GET_PRODUCTS);
    }

    let arr_products = [];
    const docProducts = await queryFindProducts.get();
    docProducts.forEach((product) =>
      arr_products.push({ ...product.data(), id: product.id, ref: product })
    );

    return arr_products;
  } catch (error) {
    console.log(error);
    throw new Error(error.message);
  }
};

export const getProduct = async ({ idProduct = "" }) => {
  try {
    const docProduct = await refProducts.doc(idProduct).get();

    if (!docProduct.exists) return null;

    return { ...docProduct.data(), id: idProduct };
  } catch (error) {
    console.log(error);
    throw new Error(error.message);
  }
};

export const saveProduct = async ({
  id = "",
  categorie = "",
  color = "",
  name = "",
  price = 0,
  sizes = [],
  details = "",
  quantityImages = 0,
  mainImage = 0,
  isNew = false,
}) => {
  try {
    if (isNew)
      return await refProducts.add({
        categorie,
        color,
        name,
        price,
        sizes,
        details,
        quantityImages,
        mainImage,
        date: new Date(),
      });

    return await refProducts.doc(id).set(
      {
        categorie,
        color,
        name,
        price,
        sizes,
        details,
        quantityImages,
        mainImage,
      },
      { merge: true }
    );
  } catch (error) {
    console.log(error);
    throw new Error(error.message);
  }
};

export const deleteProduct = async (idProduct) => {
  try {
    const docProduct = await refProducts.doc(idProduct).get();

    if (!docProduct.exists) return null;

    let quantityImages = docProduct.data().quantityImages;

    if (quantityImages > 0) {
      let promises = [];
      for (let i = 0; i < quantityImages; i++)
        promises.push(
          storageFirebase
            .ref()
            .child("/products/" + idProduct + "/" + i + ".webp")
            .delete()
        );
      await Promise.all(promises);
    }
    await refProducts.doc(idProduct).delete();
    return;
  } catch (error) {
    console.log(error);
    throw new Error(error.message);
  }
};

export const uploadImgProduct = async (idProduct, idImage, img) => {
  const storage = storageFirebase
    .ref()
    .child("/products/" + idProduct + "/" + idImage + ".webp");
  return await storage
    .put(img)
    .then(() => {
      return true;
    })
    .catch(function (error) {
      console.log(error);
      throw new Error("upload-img-error");
    });
};
