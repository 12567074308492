import { logEvent } from "FirebaseControllers/analytics";
import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";

const BagContext = React.createContext({});

export function BagContextProvider({ children }) {
  const [itemsBag, setItemsBag] = useState([]);

  const { addToast } = useToasts();

  const handleAddItemBag = (item) => {
    logEvent("add_to_cart", {
      id_product: item.id,
      size: item.size,
      color: item.color,
      name: item.name,
    });
    let _itemsBag = [...itemsBag];
    _itemsBag.push(item);
    addToast(<p>¡Producto añadido a la bolsa!</p>, {
      appearance: "success",
      autoDismiss: true,
    });
    setItemsBag(_itemsBag);
  };

  const handleRemoveItemBag = (item) => {
    let _itemsBag = itemsBag.filter((_item) => _item !== item);
    setItemsBag(_itemsBag);
  };

  return (
    <BagContext.Provider
      value={{ itemsBag, setItemsBag, handleAddItemBag, handleRemoveItemBag }}
    >
      {children}
    </BagContext.Provider>
  );
}

export default BagContext;
