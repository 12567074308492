import React, { useState } from "react";

const AdminContext = React.createContext({});

export function AdminContextProvider({ children }) {
  const [admin, setAdmin] = useState(null);

  return (
    <AdminContext.Provider value={{ admin, setAdmin }}>
      {children}
    </AdminContext.Provider>
  );
}

export default AdminContext;
