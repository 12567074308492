import DataContext from "components/Context/DataContext";
import FiltersContext from "components/Context/FiltersContext";
import Burger from "components/NavBar/Burger";
import React, { useContext } from "react";
import ReactDOM from "react-dom";
import Accordion from "../../Accordion";
import ListSelect from "../ListSelect";

import "./css.css";

export default function Modal({
  openModal = false,
  handleCloseModalMobile = () => {},
}) {
  const {
    filterSizes,
    selectFilterSize,
    filterColors,
    selectFilterColors,
    filterOrderBy,
    selectFilterOrderBy,
  } = useContext(FiltersContext);

  const { sizes, colors, orderBy } = useContext(DataContext);

  return ReactDOM.createPortal(
    <div
      className={`container-modal ${
        openModal === true
          ? "container-modal-open"
          : openModal === false
          ? "container-modal-close"
          : ""
      }`}
    >
      <div
        className={`container-modal-filter-products ${
          openModal === true
            ? "modal-filter-products-open"
            : openModal === false
            ? "modal-filter-products-close"
            : ""
        }`}
      >
        <div className="modal-filter-products-section modal-filter-products-section-close toggle">
          <p className="modal-filer-products-title">FILTRAR</p>
          <div onClick={handleCloseModalMobile}>
            <Burger dark />
          </div>
        </div>
        <Accordion
          title="Ordenar por"
          styleModal
          valuesSelect={filterOrderBy}
          children={
            <ListSelect
              value={filterOrderBy}
              setValue={selectFilterOrderBy}
              items={orderBy}
            />
          }
        />
        <Accordion
          title="Talles"
          styleModal
          valuesSelect={filterSizes}
          children={
            <ListSelect
              value={filterSizes}
              setValue={selectFilterSize}
              items={sizes}
            />
          }
        />
        <Accordion
          title="Color"
          styleModal
          valuesSelect={filterColors}
          children={
            <ListSelect
              value={filterColors}
              setValue={selectFilterColors}
              items={colors}
            />
          }
        />
      </div>
    </div>,
    document.getElementById("modal")
  );
}
