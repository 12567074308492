import React from "react";
import ReactDOM from "react-dom";
import { NavLink } from "react-router-dom";
import Burger from "../Burger";

//import { ReactComponent as DropSvg } from "img/drop.svg";

import "./css.css";

export default function MobileMenu({
  array_buttons = [],
  handleCloseMenuMobile = () => {},
  openMenu = false,
}) {
  return ReactDOM.createPortal(
    <div
      className={`container-modal ${
        openMenu === true
          ? "container-modal-open"
          : openMenu === false
          ? "container-modal-close"
          : ""
      }`}
    >
      <nav
        className={`container-mobile-menu ${
          openMenu === true
            ? "mobile-menu-open"
            : openMenu === false
            ? "mobile-menu-close"
            : ""
        }`}
      >
        <div className="mobile-menu-section mobile-menu-section-close toggle">
          {/* <p className="modal-filer-products-title">CATEGORIAS</p> */}
          <div onClick={handleCloseMenuMobile}>
            <Burger dark />
          </div>
        </div>
        {Array.isArray(array_buttons) &&
          array_buttons.map((b, i) => {
            return b.to ? (
              <NavLink
                key={i}
                to={b.to}
                onClick={handleCloseMenuMobile}
                className="mobile-menu-section"
                activeClassName="mobile-menu-section-select"
              >
                {b.text}
              </NavLink>
            ) : (
              <div key={i} onClick={b.action} className="mobile-menu-section">
                {b.text}
              </div>
            );
          })}
        {/* <div className="mobile-menu-container-footer">
          <DropSvg className="mobile-menu-icon" />
          <a
            href="https://www.instagram.com/__drppn/"
            rel="noopener noreferrer"
            target="_blank"
            className="mobile-menu-instagram"
          >
            Visita nuestro Instagram
          </a>
        </div> */}
      </nav>
    </div>,
    document.getElementById("modal")
  );
}
