import Loader from "components/Loader";
import React, { useEffect, useRef } from "react";

export default function NearScreen({
  distance = "10px",
  handleNearScreen = () => {},
}) {
  const fromRef = useRef();

  useEffect(() => {
    const fromElement = fromRef.current;
    if (!fromElement) return;

    const onChange = (entries, observer) => {
      const el = entries[0];
      if (el.isIntersecting) {
        handleNearScreen();
        observer.disconnect();
      }
    };

    const observer = new IntersectionObserver(onChange, {
      rootMargin: distance,
    });

    if (fromElement) observer.observe(fromElement);

    return () => observer && observer.disconnect();
  }, [fromRef, distance, handleNearScreen]);

  return (
    <div ref={fromRef} className="flex-center">
      <Loader />
    </div>
  );
}
