import React, { useEffect, useState } from "react";

import {
  ORDER_BY_HIGHER_PRICE,
  ORDER_BY_LOWER_PRICE,
} from "components/constraints";
import { getData } from "FirebaseControllers/data";

const DataContext = React.createContext({});

export function DataContextProvider({ children }) {
  const [categories, setCategories] = useState([]);

  const [colors, setColors] = useState([]);

  const [sizes, setSizes] = useState([]);

  const [measures, setMeasures] = useState([]);

  const orderBy = [ORDER_BY_HIGHER_PRICE, ORDER_BY_LOWER_PRICE];

  const [products, setProducts] = useState("loading");

  const [dataLoading, setDataLoading] = useState(false);

  const actualizeData = ({ categories, colors, sizes }) => {
    setCategories(categories);
    setColors(colors);
    setSizes(sizes);
  };

  useEffect(() => {
    if (!dataLoading) {
      getData()
        .then((data) => {
          if (!data) setDataLoading("error");
          else {
            setDataLoading(true);
            setCategories(data.categories);
            setColors(data.colors);
            setSizes(data.sizes);
            setMeasures(data.measures);
          }
        })
        .catch((e) => {
          console.log(e);
          setDataLoading("error");
          setCategories([]);
          setColors([]);
          setSizes([]);
          setMeasures([]);
        });
    }
  }, [dataLoading]);

  return (
    <DataContext.Provider
      value={{
        categories,
        setCategories,
        colors,
        setColors,
        products,
        setProducts,
        sizes,
        setSizes,
        orderBy,
        dataLoading,
        setDataLoading,
        actualizeData,
        measures,
        setMeasures,
      }}
    >
      {children}
    </DataContext.Provider>
  );
}

export default DataContext;
