import React from "react";
import "./css.css";

export default function Burger({
  handleOpenMenuMobile = () => {},
  dark = false,
}) {
  return (
    <div
      className={`burger ${dark ? "burger-dark" : ""}`}
      onClick={handleOpenMenuMobile}
    >
      <div className="line1"></div>
      <div className="line2"></div>
      <div className="line3"></div>
    </div>
  );
}
