import React, { useContext, useState } from "react";
import Accordion from "components/Accordion";
import DataContext from "components/Context/DataContext";

export default function Measures({ categorie = "" }) {
  const { measures } = useContext(DataContext);

  /* return (
    <table className="table-measures">
      <thead>
        <tr>
          <th>Talle</th>
          <th>Ancho</th>
          <th>Largo</th>
        </tr>
      </thead>
      <tbody>
        {measures &&
          measures.map((measure) => (
            <tr key={measure.size}>
              <td>{measure.size}</td>
              <td>{measure.width}</td>
              <td>{measure.height}</td>
            </tr>
          ))}
      </tbody>
    </table>
  ); */

  const [selectSize, setSelectSize] = useState(0);

  if (
    !measures ||
    !Array.isArray(measures[categorie]) ||
    measures[categorie].length <= 0
  )
    return null;

  return (
    <Accordion
      styles={{ backgroundColor: "transparent", padding: "10px 0" }}
      fontSizeTitle={14}
      title="Medidas"
      children={
        <div className="product-info-container-details">
          <p className="product-info-details-title">MEDIDAS</p>
          <p className="product-info-details-subtitle">
            {categorie.toUpperCase()}
          </p>

          <div className="container-measures">
            <div
              style={{ marginBottom: "10px" }}
              className="product-info-container-sizes"
            >
              {Array.isArray(measures[categorie]) &&
                measures[categorie].map((measure, index) => (
                  <div
                    key={measure.size}
                    className={`product-info-size ${
                      selectSize === index ? "product-info-size-select" : ""
                    }`}
                    onClick={() => setSelectSize(index)}
                  >
                    {measure.size}
                  </div>
                ))}
            </div>
            <p className="measures-text">
              <b>ANCHO:</b> {measures[categorie][selectSize].width}
            </p>
            <p className="measures-text">
              <b>LARGO:</b> {measures[categorie][selectSize].height}
            </p>
          </div>
        </div>
      }
    />
  );
}
