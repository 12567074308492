import React, { useRef, useState } from "react";

const ScrollContext = React.createContext({});

export function ScrollContextProvider({ children }) {
  const refAppContainer = useRef(null);
  const [scrollProducts, setScrollProducts] = useState(null);

  const scrollStart = () => {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
  };

  return (
    <ScrollContext.Provider
      value={{
        scrollProducts,
        setScrollProducts,
        refAppContainer,
        scrollStart,
      }}
    >
      {children}
    </ScrollContext.Provider>
  );
}

export default ScrollContext;
