import Accordion from "components/Accordion";
import DataContext from "components/Context/DataContext";
import ScrollContext from "components/Context/ScrollContext";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";

import ImageGallery from "react-image-gallery";

import "./css.css";
import BagContext from "components/Context/BagContext";
import { useToasts } from "react-toast-notifications";
import { getUrlImg } from "FirebaseControllers/config";
import { getProduct } from "FirebaseControllers/products";
import Loader from "components/Loader";
import NotFoundPage from "components/NotFoundPage";
import CurrencyFormat from "react-currency-format";
import Measures from "./Measures";

export default function Product() {
  const { sizes, products } = useContext(DataContext);

  const { scrollStart } = useContext(ScrollContext);

  const { handleAddItemBag } = useContext(BagContext);

  const { addToast } = useToasts();

  const { id } = useParams();

  const [idProduct, setIdProduct] = useState(id);

  const [product, setProduct] = useState("loading");

  const [sizeSelect, setSizeSelect] = useState(null);

  const sizeAvailable = (size) => {
    if (Array.isArray(product.sizes)) {
      return product.sizes.includes(size);
    }
    return false;
  };

  const addItemBag = () => {
    if (!sizeSelect) {
      addToast("Selecciona un talle", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }
    handleAddItemBag({
      ...product,
      size: sizeSelect,
      date: new Date(),
      random: Math.floor(Math.random() * 1000),
    });
  };

  useEffect(() => {
    scrollStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (product === "loading" || idProduct !== id) {
      setIdProduct(id);
      if (Array.isArray(products)) {
        let _product = products.find((product) => product.id === id);
        if (_product) {
          setProduct(_product);
          return;
        }
      }
      getProduct({ idProduct: id })
        .then(setProduct)
        .catch((e) => {
          console.log(e);
          setProduct("error");
        });
    }
  }, [id, idProduct, product, products]);

  const productImages = useMemo(() => {
    let arr_images = [];
    if (product && product.quantityImages) {
      for (let i = 0; i < product.quantityImages; i++) {
        arr_images.push({
          original: getUrlImg(product.id, i),
          thumbnail: getUrlImg(product.id, i),
        });
      }
    }
    return arr_images;
  }, [product]);

  if (product === "loading")
    return (
      <div style={{ height: window.innerHeight }}>
        <Loader />
      </div>
    );

  if (product === "error")
    return (
      <div className="container-error">
        <p className="error-title">¡UPS!</p>
        <p className="error-message">
          Ocurrió un error al cargar el producto, vuelve a intentarlo más tarde.
        </p>
      </div>
    );

  if (!product) return <NotFoundPage />;

  return (
    <div className="container-product max-width appear-window">
      <div className="product-container-thumbnails-image">
        <ImageGallery
          additionalClass="product-container-carousel"
          showBullets={true}
          showPlayButton={false}
          showThumbnails={true}
          showFullscreenButton={false}
          thumbnailPosition={window.innerWidth < 800 ? "bottom" : "left"}
          items={productImages}
        />
        <h1 className="product-name only-mobile">{product.name}</h1>
      </div>
      <div style={{ display: "flex" }}>
        {/* flex for the accordion responsive */}
        <div className="product-container-info">
          <div className="product-info-container-basic">
            <h1 className="product-name">{product.name}</h1>

            <p className="product-price">
              <CurrencyFormat
                value={product.price}
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={"$"}
              />
            </p>
          </div>

          {/* <div className="product-info-container-color">
          <div className="product-info-color">
            <div
              className={"product-info-color-content color-" + product.color}
            ></div>
          </div>
        </div> */}

          <hr className="product-hr" />

          <p className="product-info-subtitle">Talle</p>
          <div className="product-info-container-sizes">
            {Array.isArray(sizes) &&
              sizes.map((size, i) => (
                <div
                  key={size + i}
                  className={`product-info-size ${
                    sizeSelect === size && "product-info-size-select"
                  } ${!sizeAvailable(size) && "product-info-size-disabled"}`}
                  onClick={() =>
                    sizeAvailable(size) &&
                    setSizeSelect(size === sizeSelect ? null : size)
                  }
                >
                  {size}
                </div>
              ))}
          </div>

          <Measures categorie={product.categorie} />

          {product.details && (
            <Accordion
              styles={{ backgroundColor: "transparent", padding: "10px 0" }}
              fontSizeTitle={14}
              title="Detalles y material"
              withBackgroundColor={false}
              children={
                <div className="product-info-container-details">
                  <p className="product-info-details-title">DETALLES</p>
                  <p className="product-info-details-subtitle">
                    {product.name}
                  </p>

                  <p className="product-info-details-detail">
                    {product.details.split("\n").map((item, key) => (
                      <span key={key}>
                        {item}
                        <br />
                      </span>
                    ))}
                  </p>
                </div>
              }
            />
          )}

          <div className="product-info-container-add-to-cart">
            <button
              onClick={addItemBag}
              className="button button-gradient-blue"
            >
              AÑADIR A LA BOLSA
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
