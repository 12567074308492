import React from "react";
import { ReactComponent as DropSvg } from "img/drop.svg";

import "./css.css";
import Loader from "components/Loader";

export default function Splash() {
  const height = window.innerHeight;

  return (
    <div style={{ height }} className="container-splash">
      <DropSvg className="splash-icon" />
      <div style={{ position: "relative" }}>
        <p className="splash-text">DRPPN</p>
        <p className="splash-tm">TM</p>
      </div>
      <Loader style={{ margin: 0 }} />
    </div>
  );
}
