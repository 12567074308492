import React from "react";

export default function ListSelect({
  items = ["item"],
  value = 0,
  setValue = () => {},
}) {
  const isSelect = (item) => {
    if (Array.isArray(value)) return value.includes(item);
    else return value === item;
  };

  return (
    <ul>
      {Array.isArray(items) &&
        items.map((item) => (
          <li
            onClick={() => setValue(item)}
            className="accordion-item-list"
            key={item}
          >
            <div className="accordion-circle">
              {isSelect(item) && (
                <div className="accordion-circle-select fade-in">
                  <div className="accordion-circle-select-content"></div>
                </div>
              )}
            </div>
            {item}
          </li>
        ))}
    </ul>
  );
}
