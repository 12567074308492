import React from "react";
import "./css.css";
import { NavLink } from "react-router-dom";

export default function Buttons({ array_buttons = [] }) {
  return (
    <div className="buttons">
      {Array.isArray(array_buttons) &&
        array_buttons.map((button, i) => {
          return button.to ? (
            <NavLink
              key={i}
              to={button.to}
              className="nav-button"
              activeClassName="nav-button-select"
            >
              {button.text}
            </NavLink>
          ) : (
            <div key={i} onClick={button.action} className="nav-button">
              {button.text}
            </div>
          );
        })}
    </div>
  );
}
