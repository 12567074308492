import DataContext from "components/Context/DataContext";
import Loader from "components/Loader";
import { saveMeasures } from "FirebaseControllers/data";
import React, { useContext, useState } from "react";
import { useToasts } from "react-toast-notifications";

import "./css.css";
import ListMeasures from "./ListMeasures";

export default function Measures() {
  const { measures, categories } = useContext(DataContext);

  const [data, setData] = useState(measures);

  const { addToast } = useToasts();

  const onChangeInput = (value, index, categorie, propertie) => {
    let new_arr = [...data[categorie]];
    new_arr[index][propertie] = value;
    setData((prevData) => ({ ...prevData, [categorie]: new_arr }));
  };

  const addMeasure = (categorie) => {
    console.log(categorie);
    let new_arr = data[categorie] ? [...data[categorie]] : [];
    console.log(new_arr);
    new_arr.push({ size: "S", width: "", height: "" });
    setData((prevData) => ({ ...prevData, [categorie]: new_arr }));
  };

  const removeMeasure = (categorie, index) => {
    let new_arr = data[categorie].filter((_, i) => i !== index);
    setData((prevData) => ({ ...prevData, [categorie]: new_arr }));
  };

  const resetFilters = () => {
    if (window.confirm("Resetear filtros?")) {
      setData(measures);
    }
  };

  const [loading, setLoading] = useState(false);

  const handleSaveMeasures = () => {
    setLoading(true);
    console.log(data);
    saveMeasures(data)
      .then(() => {
        setLoading(false);
        addToast("Filtros guardados con éxito.", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        addToast("Ocurrio un error: " + error.message, {
          appearance: "error",
          autoDismiss: false,
        });
      });
  };

  if (loading) return <Loader />;

  return (
    <div className="container-filters">
      <p className="filters-title">MEDIDAS Y TALLES</p>

      <div className="filters-container-lists">
        {categories.map((categorie) => (
          <ListMeasures
            title={categorie}
            measures={data[categorie]}
            key={categorie}
            onChange={onChangeInput}
            addMeasure={addMeasure}
            removeMeasure={removeMeasure}
          />
        ))}
      </div>

      <button className="button" onClick={resetFilters}>
        Descartar cambios
      </button>

      <button
        className="button button-gradient-blue"
        style={{ width: "300px" }}
        onClick={handleSaveMeasures}
      >
        GUARDAR MEDIDAS
      </button>
    </div>
  );
}
