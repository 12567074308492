import React from "react";
import { ReactComponent as NotFoundSvg } from "img/not-found.svg";
import "./css.css";
import { Link } from "react-router-dom";

export default function NotFoundPage({
  text = "NO PUDIMOS ENCONTRAR LA PÁGINA QUE ESTAS BUSCANDO",
  button = (
    <Link to="/" style={{ marginTop: "50px" }} className="button">
      Volver a inicio
    </Link>
  ),
}) {
  return (
    <div className="container-not-found-page">
      <p>¡UPS!</p>
      <NotFoundSvg className="not-found-svg" />
      <p>{text}</p>
      {button}
    </div>
  );
}
