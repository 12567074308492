import React, { useContext, useState } from "react";
import Buttons from "./Buttons";
import Burger from "./Burger";
import "./css.css";
import { Link } from "react-router-dom";
import { ReactComponent as BagSvg } from "img/bag.svg";
//import { ReactComponent as DropSvg } from "img/drop.svg";
import MobileMenu from "./MobileMenu";
import DataContext from "components/Context/DataContext";
import Bag from "components/Bag";
import BagContext from "components/Context/BagContext";
import AdminContext from "components/Context/AdminContext";

export default function NavBar() {
  const { categories } = useContext(DataContext);
  const { itemsBag } = useContext(BagContext);
  const { admin } = useContext(AdminContext);

  let array_buttons = Array.isArray(categories)
    ? categories.map((categorie) => ({
        text: categorie.toUpperCase(),
        to: "/categories/" + categorie.toLowerCase(),
      }))
    : [];

  array_buttons.unshift({ text: "TODAS LAS PRENDAS", to: "/" });

  if (admin) {
    array_buttons.push({ text: "PANEL DE ADMINISTRADOR", to: "/admin/panel" });
  }

  const [openMenu, setOpenMenu] = useState(null);

  const handleCloseMenuMobile = () => setOpenMenu(false);

  const handleOpenMenuMobile = () => setOpenMenu(true);

  const [openBag, setOpenBag] = useState(null);

  const handleCloseBag = () => setOpenBag(false);

  const handleOpenBag = () => setOpenBag(true);

  return (
    <div className="content-nav">
      <div className={`nav max-width`}>
        <MobileMenu
          openMenu={openMenu}
          handleCloseMenuMobile={handleCloseMenuMobile}
          array_buttons={array_buttons}
        />
        <Burger handleOpenMenuMobile={handleOpenMenuMobile} />
        <div className="nav-logo-buttons">
          <Link className="navbar-container-logo" to="/">
            {/* <DropSvg className="navbar-logo-drop" /> */}
            <p className="navbar-logo-text">DRPPN</p>
            <p className="navbar-logo-tm">TM</p>
          </Link>
          <Buttons array_buttons={array_buttons} />
        </div>
        {admin ? (
          <p>Admin</p>
        ) : (
          <div onClick={handleOpenBag} className="container-nav-bag">
            <BagSvg className="nav-bag" />
            {itemsBag.length > 0 && (
              <span className="bag-quantity-items">{itemsBag.length}</span>
            )}
          </div>
        )}
        <Bag openModal={openBag} handleCloseBag={handleCloseBag} />
      </div>
    </div>
  );
}
