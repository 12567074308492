import React from "react";
import "./css.css";

export default function Skeleton({
  width = 20,
  height = 20,
  borderRadius = 10,
  marginBottom = 0,
  marginRight = 0,
}) {
  return (
    <div
      style={{
        height: isNaN(height) ? height : height + "px",
        width: isNaN(width) ? width : width + "px",
        borderRadius: isNaN(borderRadius) ? borderRadius : borderRadius + "px",
        marginBottom: isNaN(marginBottom) ? marginBottom : marginBottom + "px",
        marginRight: isNaN(marginRight) ? marginRight : marginRight + "px",
      }}
      className="container-skeleton"
    >
      <div className="loading-skeleton" />
    </div>
  );
}
