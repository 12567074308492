import BagContext from "components/Context/BagContext";
import { getUrlImg } from "FirebaseControllers/config";
import React, { useContext, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { useImage } from "react-image";
import { Link } from "react-router-dom";

export default function ProductBag({
  product = {
    id: "",
    name: "",
    img: "",
  },
  handleCloseBag = () => {},
}) {
  const { handleRemoveItemBag } = useContext(BagContext);

  const [disappear, setDisappear] = useState(false);

  const removeItem = () => setDisappear(true);

  const handleOnAnimationEnd = (e) => {
    console.log(e.animationName);
    if (e.animationName === "product-bag-disappear")
      handleRemoveItemBag(product);
  };

  const { src } = useImage({
    srcList: getUrlImg(product.id, 0),
    useSuspense: false,
  });

  return (
    <div
      onAnimationEnd={handleOnAnimationEnd}
      className={`container-product-bag ${
        disappear ? "product-bag-disappear" : ""
      }`}
    >
      <div className="product-bag-container-product">
        <div className="product-bag-container-image">
          {src && (
            <img
              alt="Imagen del producto"
              src={getUrlImg(product.id, 0)}
              className="product-bag-image fade-in"
            />
          )}
        </div>
        <div className="product-bag-container-info">
          <p className="product-bag-name">{product.name}</p>
          <p className="product-bag-detail">Talle: {product.size}</p>
          <p className="product-bag-detail">Color: {product.color}</p>
          <p className="product-bag-price">
            <CurrencyFormat
              value={product.price}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              prefix={"$"}
            />
          </p>
        </div>
      </div>
      <div
        className="container-double-buttons"
        style={{ marginTop: "20px", width: "90%" }}
      >
        <Link
          to={"/product/" + product.id}
          onClick={handleCloseBag}
          className="button"
        >
          Ver
        </Link>
        <button onClick={removeItem} className="button">
          Eliminar
        </button>
      </div>
    </div>
  );
}
