import firebase from "firebase/app";
import { authFirebase, refAdmin } from "./config";

export const getAdmin = async () => {
  try {
    const currentUser = authFirebase.currentUser;
    if (!currentUser) return null;
    const uid = currentUser.uid;
    if (!uid) return null;
    const docAdmin = await refAdmin.doc(uid).get();

    if (!docAdmin.exists) return null;

    return docAdmin.data();
  } catch (error) {
    console.log(error);
    throw new Error(error.message);
  }
};

export const signUpGoogle = () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  return authFirebase
    .signInWithPopup(provider)
    .then((e) => console.log(e))
    .catch((e) => {
      console.log(e);
      throw new Error(e.code);
    });
};

export const signOut = () => authFirebase.signOut();
