import FiltersProducts from "components/FiltersProducts";
import React, { useContext, useEffect, useState } from "react";

import "./css.css";
import ListProducts from "components/ListProducts";
import { useHistory, useParams } from "react-router";
import FiltersContext from "components/Context/FiltersContext";
import { logEvent } from "FirebaseControllers/analytics";

export default function Shop() {
  const { categorie } = useParams();

  const history = useHistory();

  const [idProduct, setIdProduct] = useState(null);

  const { filterCategorie, setFilterCategorie } = useContext(FiltersContext);

  const handleOnEndAnimation = (e) => {
    if (e.animationName === "disappear-window")
      history.push("/product/" + idProduct);
  };

  const handleClickProduct = (id_product) => {
    if (!idProduct) {
      logEvent('view_item', {id_product})
      setIdProduct(id_product);
    }
  };

  useEffect(() => {
    if (categorie !== filterCategorie) setFilterCategorie(categorie);
  }, [categorie, filterCategorie, setFilterCategorie]);

  return (
    <div
      onAnimationEnd={handleOnEndAnimation}
      className={`container-shop max-width ${
        idProduct ? "disappear-window" : "appear-window"
      }`}
    >
      <FiltersProducts categorie={categorie} />
      <ListProducts
        handleClickProduct={handleClickProduct}
        categorie={categorie}
      />
    </div>
  );
}
