import "./App.css";
import NavBar from "./components/NavBar";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import Shop from "components/Shop";
import Footer from "components/Footer";
import Product from "components/Product";
import NotFoundPage from "components/NotFoundPage";
import { FiltersContextProvider } from "components/Context/FiltersContext";
import { useContext, useEffect } from "react";
import ScrollContext from "components/Context/ScrollContext";

import "react-image-gallery/styles/css/image-gallery.css";
import { BagContextProvider } from "components/Context/BagContext";
import { ToastProvider } from "react-toast-notifications";
import { authFirebase } from "FirebaseControllers/config";
import { getAdmin } from "FirebaseControllers/admin";
import Login from "components/Login";
import AdminContext from "components/Context/AdminContext";
import ProductAdmin from "components/admin/ProductAdmin";
import DataContext from "components/Context/DataContext";
import Splash from "components/Splash";
import Filters from "components/admin/FiltersAdmin";
import PanelAdmin from "components/admin/PanelAdmin";
import Measures from "components/admin/MeasuresAdmin";
import ErrorBoundaryWithRouter from "components/ErrorBoundary";

function App() {
  const { refAppContainer } = useContext(ScrollContext);

  const { admin, setAdmin } = useContext(AdminContext);

  const { dataLoading, setDataLoading } = useContext(DataContext);

  useEffect(() => {
    const authChanged = authFirebase.onAuthStateChanged((account) => {
      if (account) {
        getAdmin()
          .then(setAdmin)
          .catch((e) => {
            console.log(e);
            setAdmin(null);
          });
      } else {
        setAdmin(null);
      }
    });

    return () => authChanged();
  }, [setAdmin]);

  if (!dataLoading) return <Splash />;

  if (dataLoading === "error")
    return (
      <div
        style={{
          height: window.innerHeight + "px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <NotFoundPage
          text="OCURRIÓ UN ERROR"
          button={
            <button className="button" onClick={() => setDataLoading(false)}>
              Volver a intentar
            </button>
          }
        />
      </div>
    );

  return (
    <ErrorBoundaryWithRouter>
      <ToastProvider autoDismiss autoDismissTimeout={3000}>
        <FiltersContextProvider>
          <BagContextProvider>
            <HashRouter basename="/">
              <div ref={refAppContainer} className="container-app fade-in">
                <NavBar />
                <div className="container-content-app">
                  <Switch>
                    {admin && (
                      <Route
                        path="/product/new"
                        children={<ProductAdmin isNew />}
                      />
                    )}
                    <Route
                      path="/product/:id"
                      children={admin ? <ProductAdmin /> : <Product />}
                    />
                    <Route path="/categories/:categorie" children={<Shop />} />
                    <Route exact path="/">
                      <Shop />
                    </Route>
                    <Route path="/login">
                      {admin ? <Redirect to="/" /> : <Login />}
                    </Route>
                    {admin && (
                      <Route path="/admin/panel" children={<PanelAdmin />} />
                    )}
                    {admin && (
                      <Route path="/admin/measures" children={<Measures />} />
                    )}
                    {admin && (
                      <Route path="/admin/filters" children={<Filters />} />
                    )}
                    <Route path="/">
                      <NotFoundPage />
                    </Route>
                  </Switch>
                </div>
                <Footer />
              </div>
            </HashRouter>
          </BagContextProvider>
        </FiltersContextProvider>
      </ToastProvider>
    </ErrorBoundaryWithRouter>
  );
}

export default App;
