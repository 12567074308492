import DataContext from "components/Context/DataContext";
import React, { useContext } from "react";

export default function ListMeasures({
  title = "",
  description = "",
  measures = [],
  onChange = () => {},
  addMeasure = () => {},
  removeMeasure = () => {},
}) {
  const { sizes } = useContext(DataContext);

  return (
    <div className="filters-container-list">
      <p className="measure-subtitle">{title}</p>
      <p className="filters-description">{description}</p>
      <li className="filters-list">
        {Array.isArray(measures) &&
          measures.map((measure, i) => (
            <ul className="measure-list-container-item" key={i}>
              <label className="measure-desc-input">
                Talle:
                <select
                  className="input measure-list-item"
                  value={measure.size}
                  onChange={(e) => onChange(e.target.value, i, title, "size")}
                >
                  {Array.isArray(sizes) &&
                    sizes.map((size) => (
                      <option value={size} key={size}>
                        {size}
                      </option>
                    ))}
                </select>
              </label>
              <label className="measure-desc-input">
                Ancho:
                <input
                  className="input measure-list-item"
                  value={measure.width}
                  onChange={(e) => onChange(e.target.value, i, title, "width")}
                />
              </label>
              <label className="measure-desc-input">
                Alto:
                <input
                  className="input measure-list-item"
                  value={measure.height}
                  onChange={(e) => onChange(e.target.value, i, title, "height")}
                />
              </label>
              <div
                className="filter-list-item-x"
                onClick={() => removeMeasure(title, i)}
              >
                X
              </div>
            </ul>
          ))}
      </li>
      <button
        className="button filter-list-button"
        onClick={() => addMeasure(title)}
      >
        Agregar
      </button>
    </div>
  );
}
