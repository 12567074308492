import React, { useState } from "react";

const FiltersContext = React.createContext({});

export function FiltersContextProvider({ children }) {
  const [filterOrderBy, setFilterOrderBy] = useState("");

  const [filterSizes, setFilterSizes] = useState([]);

  const [filterColors, setFilterColors] = useState("");

  const [filterCategorie, setFilterCategorie] = useState("");

  const selectFilterSize = (size) => {
    let new_array_filter = [];
    const exist = filterSizes.includes(size);
    if (exist) new_array_filter = filterSizes.filter((_size) => _size !== size);
    else new_array_filter = [...filterSizes, size];
    setFilterSizes(new_array_filter);
  };

  const selectFilterColors = (color) => {
    if (color === filterColors) setFilterColors("");
    else setFilterColors(color);
  };

  const selectFilterOrderBy = (order) => {
    if (order === filterOrderBy) setFilterOrderBy("");
    else setFilterOrderBy(order);
  };

  return (
    <FiltersContext.Provider
      value={{
        filterOrderBy,
        selectFilterOrderBy,
        filterSizes,
        selectFilterSize,
        filterColors,
        selectFilterColors,
        filterCategorie,
        setFilterCategorie,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
}

export default FiltersContext;
