import DataContext from "components/Context/DataContext";
import Loader from "components/Loader";
import { saveData } from "FirebaseControllers/data";
import React, { useContext, useState } from "react";
import { useToasts } from "react-toast-notifications";

import "./css.css";
import ListFilters from "./ListFilters";

export default function Filters() {
  const { categories, colors, sizes, actualizeData } = useContext(DataContext);

  const [data, setData] = useState({ categories, colors, sizes });

  const { addToast } = useToasts();

  const onChangeInput = (value, index, filter) => {
    let new_arr = [...data[filter]];
    new_arr[index] = filter === "categories" ? value.toLowerCase() : value;
    setData((prevData) => ({ ...prevData, [filter]: new_arr }));
  };

  const addFilter = (filter) => {
    let new_arr = [...data[filter]];
    new_arr.push("");
    setData((prevData) => ({ ...prevData, [filter]: new_arr }));
  };

  const removeFilter = (filter, index) => {
    let new_arr = data[filter].filter((_, i) => i !== index);
    setData((prevData) => ({ ...prevData, [filter]: new_arr }));
  };

  const resetFilters = () => {
    if (window.confirm("Resetear filtros?")) {
      setData({ categories, colors, sizes });
    }
  };

  const [loading, setLoading] = useState(false);

  const handleSaveData = () => {
    setLoading(true);
    saveData(data)
      .then(() => {
        setLoading(false);
        addToast("Filtros guardados con éxito.", {
          appearance: "success",
          autoDismiss: true,
        });
        actualizeData(data);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        addToast("Ocurrio un error: " + error.message, {
          appearance: "error",
          autoDismiss: false,
        });
      });
  };

  if (loading) return <Loader />;

  return (
    <div className="container-filters">
      <p className="filters-title">FILTROS</p>
      <div className="filters-container-lists">
        <ListFilters
          title="Categorias"
          description="(minúsculas y plural)"
          array_filters={data.categories}
          onChange={(value, index) => onChangeInput(value, index, "categories")}
          addFilter={() => addFilter("categories")}
          removeFilter={(index) => removeFilter("categories", index)}
        />
        <ListFilters
          title="Colores"
          description="(primera mayúscula y singular)"
          array_filters={data.colors}
          onChange={(value, index) => onChangeInput(value, index, "colors")}
          addFilter={() => addFilter("colors")}
          removeFilter={(index) => removeFilter("colors", index)}
        />
        <ListFilters
          title="Talles"
          description="(mayúsculas)"
          array_filters={data.sizes}
          onChange={(value, index) => onChangeInput(value, index, "sizes")}
          addFilter={() => addFilter("sizes")}
          removeFilter={(index) => removeFilter("sizes", index)}
        />
      </div>
      <button className="button" onClick={resetFilters}>
        Descartar cambios
      </button>

      <button
        className="button button-gradient-blue"
        style={{ width: "300px" }}
        onClick={handleSaveData}
      >
        GUARDAR FILTROS
      </button>
    </div>
  );
}
