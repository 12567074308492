import React, { useState } from "react";

import "./css.css";
export default function Accordion({
  title = "",
  children = <span></span>,
  styleModal = false,
  styles = {},
  fontSizeTitle = 15,
  valuesSelect = [],
}) {
  const [openAccordion, setOpenAccordion] = useState(null);

  const [showContent, setShowContent] = useState(null);

  const handleOnEndAnimation = (e) => {
    if (e.animationName === "container-accordion-less") {
      setShowContent(false);
    }
  };

  const handleClickAccordion = () => {
    if (openAccordion) {
      setOpenAccordion(false);
    } else {
      setOpenAccordion(true);
      setShowContent(true);
    }
  };

  const getTextValuesSelect = () => {
    if (!valuesSelect) return "";
    if (Array.isArray(valuesSelect)) {
      if (valuesSelect.length > 1) {
        let text = "";
        valuesSelect.forEach((value, i) => {
          if (i === 0) text = value;
          else text += ", " + value;
        });
        return text;
      } else return valuesSelect[0];
    } else return valuesSelect;
  };

  return (
    <div
      style={styles}
      className={`container-accordion  ${
        openAccordion === true
          ? "container-accordion-more"
          : openAccordion === false
          ? "container-accordion-less"
          : ""
      } ${styleModal && "container-accordion-style-modal"}`}
      onAnimationEnd={handleOnEndAnimation}
      onClick={() => !openAccordion && handleClickAccordion()}
    >
      <div className="accordion-header" onClick={handleClickAccordion}>
        <div className="accordion-container-title-desc">
          <p
            style={{ fontSize: fontSizeTitle + "px" }}
            className="accordion-title"
          >
            {title}
          </p>
          <p className="accordion-desc">{getTextValuesSelect()}</p>
        </div>
        <div
          className={`accordion-more ${
            openAccordion === true
              ? "accordion-animation-less"
              : openAccordion === false
              ? "accordion-animation-more"
              : ""
          }`}
        >
          <div></div>
          <div></div>
        </div>
      </div>

      {(showContent || openAccordion) && children}
    </div>
  );
}
