import React from "react";
import "./css.css";

export default function Loader({ button = false, style = {} }) {
  return (
    <div style={style} className={`loader ${button ? "loader-button" : ""}`}>
      Loading...
    </div>
  );
}
